import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import './index.css'
import BrageImg from '../../assets/images/brage.png'
import Hudson from '../../assets/images/Hudson.jpeg'
import Chris from '../../assets/images/Chris.jpeg'
import Brian from '../../assets/images/Brian.jpeg'
import Scott from '../../assets/images/Scott.jpeg'
import ad_1 from '../../assets/images/ad_1.png'
import ad_2 from '../../assets/images/ad_2.png'
import ad_3 from '../../assets/images/ad_3.png'
import ad_4 from '../../assets/images/ad_4.png'
import ad_5 from '../../assets/images/ad_5.png'
import ad_6 from '../../assets/images/ad_6.png'
import ad_7 from '../../assets/images/ad_7.png'
import ad_8 from '../../assets/images/ad_8.png'
import SMallHeading from '../UIKit/SmallHeadingText'
import BlackMainHeading from '../UIKit/BlackHeadingText'

const teamMembers = {
  founders: [
    {
      image: BrageImg,
      name: 'Brage Garofalo',
      designation: 'Co-Founder',
      description:
        'Brage Garofalo has been a transition mentor and coach with Special Operators for over a decade, and through this work saw firsthand the nuanced complexities that accompany transition, even more so for Operators. Transition is more than just the technical and tactical components; but also involves an emotional and intellectual aspect that needs to be addressed as well. My360Tribe was founded in the Summer of 2023 on the premise that Operators need a member of their own Tribe within the Special Operations community to work through the challenges that arise at any time as part of their transition.In addition to co-founding My360Tribe, Brage actively consults and volunteers with numerous veteran service organizations helping to develop, build and/or improve mentor programs and mentoring platforms. She was instrumental in helping to build and manage the Mentor program for The Honor Foundation as well as develop a transition-focused eLearning library for the members of Elite Meet. She is an advisor and lecturer in areas of company and culture research and the complexities of transition for Operators. She also served as a member of the Advisory Board and Board of Directors for organizations that support veterans and their families, including transition.Brage is also the Director of Clinical Study Management for a Bay Area biotech where she builds high performing teams and leads cross functional teams to develop and execute oncology clinical trials. Brage has over 20 years of successful implementation and execution of global clinical trials that led to several FDA approvals in numerous therapeutic areas, including pediatric oncology, HIV and the first cure for Hepatitis C. Brage and her husband currently reside in the San Francisco Bay Area with their rescue dogs Charlie and Eddie.',
      tags: [
        '10 years of SOF Support',
        'Cancer Researcher',
        'Dog Lover',
        'Cage Diver'
      ]
    },
    {
      image: Hudson,
      name: 'Michael Hudson',
      designation: 'Co-Founder',
      tags: ['10 years of SOF Support', 'Banker', 'Golfer', 'Traveller'],
      description:
        'Mike is a Pittsburgh native, who grew up in Clearwater, Florida, and has lived in Roswell, GA for the past 25 years. He is currently the Chief Administrative Officer for Consumer Operations at Wells Fargo. With over 30 years in banking, Mike has held executive-level positions in credit, operations, and innovation since 2007. Mike’s passion for the Special Operations community started in August 2011 after both a significant personal tragedy and Extortion 17. For the past 12 years, he has mentored over 75 individuals, co-founded the PNC Community Mutt Strut (an event to raise money to pair Veterans with Service Dogs), and has sat on the advisory board for several organizations, including Guardian Angels and SEAL Future Foundation. Mike is married to his wife Nicole of 24 years. They have a daughter, Delaney (20), who plays Division 1 Lacrosse, and a high school senior, Luke (18). Mike is an avid golfer, playing almost 100 rounds a year, and he travels extensively with his family and for work.'
    },
    {
      image: Chris,
      name: 'Chris Phillips',
      designation: 'Board Member(Military)',
      tags: ['Marine Corps Veteran', 'Award Winner', 'PHD Candidate'],
      description:
        'Chris Phillips is a highly recognized and committed Marine Corps veteran and advocate for other veterans. During her time at PNC, she stood up the military recruiting program at PNC Bank, created a sustainable model in the Military Advisory Council, helped establish military employee groups across corporate industries and was the Military Affairs Liaison for the enterprise when she retired in 2021. As a volunteer leader, Chris is a strong advocate for the medical service dog industry, a huge supporter of the use of horses for therapy and believes these highly trained animals are a solution to end the suffering of our veterans nationwide. Her work has raised in excess of 2 million dollars for service dogs for veterans in danger of suicide. Chris was recently named to the Board of Directors for Gunny’s Ridge and has been invited to join the Board of Directors for Folds of Honor this fall. Additionally, Chris has worked extensively with USA Hockey’s Warrior Program since 2018. Chris has been nationally recognized by the US Chamber of Commerce for her work with transitioning veterans and is a two-time finalist for the Col. Endres Individual Excellence in Veteran Employment Award. She is the recipient of the 2013 Secretary of Defense Patriot Award and was named the 2023 Pittsburgh Veteran of the Year by the Military Friendly Brand through public vote… the most prestigious award given to a veteran in the region. This year, she will complete her doctorate studies in Rhetoric and Communication at Duquesne University where she is has been an Adjunct faculty member for the past three years. Chris now works in Consumer Operations at Wells Fargo. She lives in Davidson, NC has two dogs and cat, loves living on the lake most of all, loves being the mother of two beautiful grown daughters and ‘Chucka’ to her amazing granddaughters.'
    },
    {
      image: Brian,
      name: 'Brian G. Cunningham',
      designation: 'Board Member (Military)',
      tags: ['Navy (SEAL) Veteran', 'AI Executive', 'Transformational Leader'],
      description:
        'Brian’s career, both in the military and as a civilian, reflects a legacy of strategic leadership and a commitment to innovation. As a 25+ year Navy SEAL and senior Special Operations Officer, Brian led hundreds of critical operations, organizations, and US strategies across challenging environments, demonstrating unparalleled leadership, strategic fluency, and tactical acumen. His military service, highlighted by leadership in multiple combat deployments, showcases his ability to navigate complex situations with precision and integrity. Transitioning into the corporate world, Brian has continued to make significant impacts as the Chief Strategy Officer at an advanced technology company where his expertise in leading-edge computing and AI/ML has propelled the company into a new era of tech innovation. His journey from the rigorous demands of Special Operations to the dynamic challenges of the tech industry exemplifies a dedication to excellence and a continuous pursuit of impactful solutions to improve humankind. Motivated by pivotal disappointing corporate encounters and value gaps in transition, which ignited his determination to empower SOF veterans, ensuring their incredibly rare skills and elite leadership are recognized, valued, never underestimated.'
    },
    {
      image: Scott,
      name: 'Scott McKee',
      designation: 'Treasurer',
      tags: ['20 years of corporate development', 'Founder of Sidecar Group'],
      description:
        'Scott is the Founder and Managing Member of Sidecar Group. With over 20 years of corporate development experience, Scott has initiated investments, acquisitions, mergers, partnerships, divestitures, product lines and a variety of other development efforts to create value for companies. Scott has assisted The Honor Foundation as well as Seals Future Foundation members since 2019. Scott’s formal education includes a Masters of Business Administration degree and a Bachelor’s Degree from the University of North Dakota. Scott resides in the Atlanta, GA area with his wife of 25 years and their three children.'
    }
  ],
  advisoryBoard: [
    // {
    //   image: ad_1,
    //   name: `Greg 'Vinny' Vincent`,
    //   designation: 'Navy SEAL'
    //   // tags: ['Doctor', 'Philanthropist', 'Mentor']
    // },
    {
      image: ad_2,
      name: `Pat O'Rourke`,
      designation: 'Air Force (720 STG, PJ)'
      // tags: ['Engineer', 'Technologist', 'Entrepreneur']
    },
    {
      image: ad_3,
      name: 'Brian Hughes',
      designation: 'Army (160 SOAR)'
      // tags: ['Researcher', 'Scientist', 'Visionary']
    },
    {
      image: ad_4,
      name: 'Joe Hudak',
      designation: 'Army (SF)'
      // tags: ['Teacher', 'Educator', 'Innovator']
    },
    {
      image: ad_5,
      name: 'Matt Bruni',
      designation: 'Army (Ranger)'
      // tags: ['Philosopher', 'Thinker', 'Visionary']
    },
    {
      image: ad_6,
      name: 'Jason Schmidt',
      designation: 'Marine Raider'
      // tags: ['Tech Leader', 'Strategist', 'Problem Solver']
    },
    {
      image: ad_7,
      name: 'Jack Davis',
      designation: 'Navy EOD'
      // tags: ['Leader', 'Motivator', 'Achiever']
    },
    {
      image: ad_8,
      name: 'Ryan Duhon',
      designation: 'AFSOC'
      // tags: ['Engineer', 'Innovator', 'Entrepreneur']
    }
  ]
}

export default function MeetOurTeam () {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)

  const showModal = member => {
    setSelectedMember(member)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setSelectedMember(null)
  }
  return (
    <div className='meet-our-team'>
      <SMallHeading text={'More About us'} />
      <BlackMainHeading text={'Meet Our Team'} />
      {/* Founders Section */}
      <div className='team-section'>
        <h2 className='team-heading'>Founders and Founding Board</h2>
        <div className='team-grid'>
          {teamMembers.founders.map((member, index) => (
            <div
              key={index}
              className='team-card'
              onClick={() => showModal(member)}
            >
              <img
                src={member.image}
                alt={member.name}
                className='team-image founders-img'
              />
              <div className='team-info'>
                <h3 className='team-name'>{member.name}</h3>
                <p className='team-designation'>{member.designation}</p>
                <div className='team-tags'>
                  {member.tags.map((tag, idx) => (
                    <span key={idx} className='team-tag'>
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Advisory Board Section */}
      <div className='team-section'>
        <h2 className='team-heading'>Advisory Board</h2>
        <div className='team-grid advisory-grid'>
          <div
            className='team-card advisory-card-1'
            // onClick={() => showModal(member)}
          >
            <img
              src={ad_1}
              alt={`Greg 'Vinny' Vincent`}
              className='team-image advisory-img-1'
            />
            <div className='team-info'>
              <h3 className='team-name'>{`Greg 'Vinny' Vincent`}</h3>
              <p className='team-designation'>{'Navy SEAL'}</p>
            </div>
          </div>
          {teamMembers.advisoryBoard.map((member, index) => (
            <div
              key={index}
              className='team-card advisory-card'
              // onClick={() => showModal(member)}
            >
              <img
                src={member.image}
                alt={member.name}
                className='team-image advisory-img'
              />
              <div className='team-info'>
                <h3 className='team-name'>{member.name}</h3>
                <p className='team-designation'>{member.designation}</p>
                <div className='team-tags'>
                  {member.tags?.map((tag, idx) => (
                    <span key={idx} className='team-tag'>
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal
        title={selectedMember?.name}
        open={isModalVisible}
        onCancel={handleCancel}
        width={'80%'}
        footer={null}
        centered
      >
        {selectedMember && (
          <div className='modal-content'>
            <img
              src={selectedMember.image}
              alt={selectedMember.name}
              className='modal-image'
            />
            <h3>{selectedMember.designation}</h3>
            <p>{selectedMember.description}</p>
          </div>
        )}
      </Modal>
    </div>
  )
}
