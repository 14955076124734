import React from 'react'
import './index.css'
import largeImage from '../../assets/images/what-we-do.webp'

const AboutUs = () => {
  return (
    <div className='about-us'>
      {/* Large Image Section */}
      <div className='image-section'>
        <img src={largeImage} alt='About Us' className='large-image' />
      </div>

      {/* Content Section */}
      <div className='content-section'>
        <h1 className='main-heading'>My360Tribe</h1>

        <div className='text-content'>
          <section className='left-section'>
            <h2>Who We Are:</h2>
            <p>
              My 360 Tribe was founded on the premise of support and guidance
              for members of the Special Operations Forces (SOF) community as
              they embark on the pivotal journey transitioning from military
              valor to civilian achievement. Transitioning for Operators can be
              very stressful as they leave their brotherhood and community
              behind. My 360 Tribe aims to bridge that gap, providing peer
              support of Operators to Operators who have successfully
              transitioned and understand the challenges of someone who has
              walked in their shoes.
            </p>
            <p>
              Established by a cadre of SOF veterans and insightful, experienced
              civilian mentors, our organization is uniquely poised to address
              the multifaceted challenges encountered by our operators during
              this critical time.
            </p>

            <h2>Our Mission:</h2>
            <p>
              To provide peer-to-peer and concierge level support for U.S. SOF
              Operators as they face the challenges before, during and after
              transition from service
            </p>

            <h2>Our Vision:</h2>
            <p>
              To become the cornerstone of support for every SOF Operator,
              providing concierge-level peer support by providing access to a
              suite of services that cater to their holistic needs —
              professional, personal, and emotional. We envision a future where
              our heroes integrate seamlessly into civilian life, leveraging
              their unique capabilities to continue to lead, innovate, and
              inspire.
            </p>
          </section>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
